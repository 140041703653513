import React from "react";
import "./styles.scss";

const spanStyle = {
   fontSize: "16px",
   fontWeight: 600,
   letterSpacing: "0.32px",
   color: "#000"
};
const spanValueStyle = {
   fontSize: "22px",
   fontWeight: 600,
   color: "#000"
};
const OurApproach = () => {
   return (
      <>
         <div className="bodyContent-ASK" style={{ backgroundColor: "#F3F4F6" }}>
            <div className="secondBody-ASK">
               <div className="container">
                  <div className="row" style={{ margin: 0 }}>
                     <div
                        className="col-md-8 d-flex flex-column subTitleLeft"
                        style={{ gap: "16px", padding: 0 }}
                     >
                        <span
                           style={{
                              color: "#B7284D",
                              fontSize: "32px",
                              fontWeight: 600
                           }}
                        >
                           Our approach
                        </span>
                        <div className="d-flex flex-column" style={{ gap: "20px" }}>
                           <span style={{ fontSize: "16px", letterSpacing: "0.32px" }}>
                              Founded in 1948, KAS has gone through various historical stages,
                              always maintaining its main mission – providing accurate and reliable
                              statistical information in order to support fact-based
                              decision-making.
                           </span>
                           <span style={{ fontSize: "16px", letterSpacing: "0.32px" }}>
                              KAS tends to be modern in the provision of data information, striving
                              to follow international standards of high quality, necessary for
                              society, science, economy, policy-making, administration and media. To
                              achieve this, a significant factor is the production and communication
                              of facts. Data information is provided in an easily accessible and
                              understandable manner for further processing, following scientific
                              principles and using different communication channels.
                           </span>
                        </div>
                     </div>
                     <div
                        className="d-flex flex-column col-md-4 subTitleRight ourImpactSection"
                        style={{
                           gap: "20px"
                        }}
                     >
                        <h5>
                           <b>Our impact</b>
                        </h5>
                        <div className="divider bg-dark" style={{ padding: "0.1px" }}></div>
                        <div className="d-flex flex-column">
                           <span style={spanStyle}>Founded in</span>
                           <span style={spanValueStyle}>1948</span>
                        </div>
                        <div className="divider bg-dark" style={{ padding: "0.1px" }}></div>
                        <div className="d-flex flex-column">
                           <span style={spanStyle}>Products</span>
                           <span style={spanValueStyle}>5</span>
                        </div>
                        <div className="divider bg-dark" style={{ padding: "0.1px" }}></div>
                        <div className="d-flex flex-column">
                           <span style={spanStyle}>Regional offices</span>
                           <span style={spanValueStyle}>7</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default OurApproach;
