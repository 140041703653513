import "./ResponsivenessForAll.scss";
import React from "react";
const FirstSection = () => {
  return (
    <div className="d-flex flex-column align-items-center single-text-ASK">
      <div className="container">
        <div
          className="d-flex flex-column align-items-center boxStyle-ASK"
          style={{ gap: "24px" }}
        >
          <div className="d-flex flex-column" style={{ gap: "12px" }}>
            <span
              style={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: "24px",
              }}
            >
              <span style={{ color: "#000" }}>
                KAS's attention is focused on its users:
              </span>{" "}
              <span style={{ color: "#B7284D", }}>
                policy-makers, citizens, the scientific community, businesses,
                public administration, as well as international institutions.
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
