import React from "react";
import IconGrid from "./IconGrid";
import "./styles.scss";
const WhatWeDo = () => {
  return (
    <div className="third-body-ASK d-flex flex-column" style={{ gap: "40px" }}>
      <div className="container">
        <div className="subtitleBottom">
          <h5>
            <span style={{ color: "#B7284D", fontSize: "32px", fontWeight: 600 }}>
              What we do?
            </span>
          </h5>
          <span style={{ color: "#000" }}>
            The objective of KAS is to fulfill the requests of its users for
            statistical information through its products and services.
          </span>
        </div>
        <IconGrid />
      </div>
    </div>
  );
};

export default WhatWeDo;
