import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Checkbox, Spin } from "antd";
import "./styles.scss";
import Banner from "./assets/banner.svg";
import RightArrow from "./assets/right-arrow.svg";
import RightArrowBlack from "./assets/right-arrow-black.svg";
import askLogo from "./assets/ask.svg";
import kaporgLogo from "./assets/kaporg-logo-blue.svg";
import { FormattedMessage } from "react-intl";
import { EyeInvisible, EyeVisible } from "../../../../assets/icons";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../authSlice";
import { useNavigate } from "react-router-dom";

const Header = () => {
   const initialValues = {
      email: "",
      password: ""
   };

   const { isLoading } = useSelector((state) => state.auth);
   const [icon, setIcon] = useState(EyeInvisible);
   const dispatch = useDispatch();

   const [checked, setChecked] = useState(false);
   const navigate = useNavigate();

   const [type, setType] = useState("password");

   const onChange = (e) => {
      setChecked(e.target.checked);
   };

   const LoginSchema = Yup.object().shape({
      email: Yup.string()
         .email("Invalid email")
         .required("Required"),
      password: Yup.string()
         .max(16, "Maximum 16 symbols")
         .required("Required")
   });

   const handleToggle = () => {
      if (type === "password") {
         setIcon(EyeVisible);
         setType("text");
      } else {
         setIcon(EyeInvisible);
         setType("password");
      }
   };

   const [isMobile, setIsMobile] = useState(false);

   const submitForm = (values) => {
      dispatch(login(values, successCallback, errCB));
   };

   const successCallback = () => {
      navigate("/admin/workspace");
   };

   const errCB = (data) => {
      if (data.target?.targetUrl) {
         navigate(data.target?.targetUrl, {
            state: { fromApp: true, data: data.target.params }
         });
      }
   };

   useEffect(() => {
      const handleResize = () => {
         setIsMobile(window.innerWidth <= 540);
      };
      handleResize();
      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
   }, []);

   const heroContainer = (
      <div className="hero-text" style={{ marginBottom: isMobile ? "40px" : "unset" }}>
         <div className="d-flex flex-column" style={{ gap: isMobile ? "24px" : "unset" }}>
            <span
               style={{
                  fontStyle: "italic",
                  fontWeight: 700,
                  color: isMobile ? "#262633" : "#fff"
               }}
               className="spanStyle"
            >
               Kosovo Agency of <br /> Statistics (KAS)
            </span>
            <span
               style={{
                  color: isMobile ? "#262633" : "#fff",
                  fontWeight: 600,
                  fontSize: "22px"
               }}
            >
               is the primary producer of official statistics in the Republic of Kosovo
            </span>
         </div>
         <p style={{ fontSize: "16px", marginTop: isMobile ? "24px" : "unset" }}>
            The Kosovo Agency of Statistics (KAS) is the main producer of official statistics in the
            Republic of Kosovo (responsible for the collection, processing, and publication of
            data), as well as the coordinator of the Statistical System of Kosovo.
         </p>
         <a href="https://ask.rks-gov.net/" target="_blank">
            <button
               className="d-flex justify-content-center align-items-center buttonStyle"
               style={{
                  borderBottom: isMobile ? "1px solid black" : "1px solid #fff",
                  color: isMobile ? "#262633" : "#fff"
               }}
            >
               Learn more
               <img src={isMobile ? RightArrowBlack : RightArrow} alt="arrow" />
            </button>
         </a>
      </div>
   );

   return (
      <>
         <div className="headerLogo-ASK">
            <div className="container">
               <div className="logo d-flex align-items-center">
                  <img src={askLogo} alt="ask agency logo" />

                  <div className="vertical-line"></div>

                  <img src={kaporgLogo} alt="KAPorg logo" />
               </div>
            </div>
         </div>

         {isMobile && heroContainer}

         <div className="hero-bg-ASK">
            <div className="img-bg">
               <img src={Banner} alt="img-background" />
               <div className="banner-overlay" />
            </div>
            <div className="container">
               <div className="hero-banner-content-ASK">
                  {!isMobile && heroContainer}
                  {/* <div className="hero-text">
              <div className="d-flex flex-column">
                <span
                  style={{
                    fontStyle: "italic",
                    fontWeight: 700,
                    color: "#fff",
                  }}
                  className="spanStyle"
                >
                  Kosovo Agency of <br /> Statistics (KAS)
                </span>
                <span
                  style={{ color: "#fff", fontWeight: 600, fontSize: "22px" }}
                >
                  is the primary producer of official statistics in the Republic
                  of Kosovo
                </span>
              </div>
              <p style={{ fontSize: "16px" }}>
                The Kosovo Agency of Statistics (KAS) is the main producer of
                official statistics in the Republic of Kosovo (responsible for
                the collection, processing and publication of data), as well as
                the coordinator of the Statistical System of Kosovo.
              </p>
              <button
                className="d-flex justify-content-center align-items-center"
                style={{ borderBottom: "1px solid #fff", width: "fit-content" }}
              >
                Learn more
                <img src={RightArrow} alt="arrow" />
              </button>
            </div> */}
                  <div className="hero-form justify-content-center justify-content-lg-end">
                     <>
                        {/* {isLoading ? (
                    <BlockUI isLoading={isLoading} />
                ) : ( */}
                        <div
                           className="pt-md-20 pr-md-10 pb-md-20 pl-md-10 custom-div"
                           style={{
                              margin: "0 !important",
                              display: "block",
                              padding: "60px 40px 60px 40px",
                              height: "fit-content",
                              backgroundColor: "#fff"
                           }}
                        >
                           <div
                              className="d-flex flex-column"
                              style={{ gap: "8px", marginBottom: "40px" }}
                           >
                              <p
                                 style={{
                                    color: "#B7284D",
                                    fontWeight: 600,
                                    fontSize: "26px",
                                    margin: 0
                                 }}
                              >
                                 Welcome back!
                              </p>
                              <p className="m-0">Login into your account</p>
                           </div>
                           <Formik
                              initialValues={initialValues}
                              validationSchema={LoginSchema}
                              onSubmit={(values) => {
                                 submitForm(values);
                              }}
                           >
                              {({ handleSubmit, handleChange, values, errors }) => (
                                 <form
                                    onSubmit={handleSubmit}
                                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                                 >
                                    <div
                                       style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "30px"
                                       }}
                                    >
                                       <div className="form-group fv-plugins-icon-container">
                                          <label
                                             style={{
                                                fontSize: "16px",
                                                color: "#3A3737",
                                                fontWeight: 500,
                                                marginBottom: "10px"
                                             }}
                                          >
                                             Email
                                          </label>
                                          <input
                                             placeholder="yourmail@gmail.com"
                                             className={`form-control`}
                                             type="email"
                                             name="email"
                                             onChange={handleChange}
                                             values={values.email}
                                          />
                                          {errors.email && (
                                             <div className="error-message">{errors.email}</div>
                                          )}
                                       </div>
                                       <div className="form-group fv-plugins-icon-container">
                                          <label
                                             style={{
                                                fontSize: "16px",
                                                color: "#3A3737",
                                                fontWeight: 500,
                                                marginBottom: "10px"
                                             }}
                                          >
                                             Password
                                          </label>
                                          <div className="password-section">
                                             <input
                                                autoComplete="off"
                                                placeholder="Password"
                                                className={`form-control`}
                                                type={type}
                                                name="password"
                                                onChange={handleChange}
                                                values={values.password}
                                             />
                                             <img
                                                className="eyeButton"
                                                onClick={handleToggle}
                                                src={icon}
                                                alt="showPasswordIcon"
                                                style={{ width: "14px" }}
                                             />
                                          </div>

                                          {errors.password && (
                                             <div className="error-message">{errors.password}</div>
                                          )}
                                       </div>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mt-2">
                                       <div className="d-flex align-items-center">
                                          <Checkbox
                                             checked={checked}
                                             onChange={onChange}
                                             style={{ lineHeight: "0px" }}
                                          />
                                          <p
                                             style={{
                                                fontSize: "12px",
                                                color: "#777",
                                                margin: "0 0 0 8px"
                                             }}
                                          >
                                             Remember me?
                                          </p>
                                       </div>
                                       <Link
                                          to="/auth/forgot-password"
                                          className="mr-4 password-text"
                                          id="kt_login_forgot"
                                          style={{ fontSize: "12px" }}
                                       >
                                          <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                                       </Link>
                                    </div>
                                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                                       <button
                                          id="kt_login_signin_submit"
                                          type="submit"
                                          className={`btn sign-btn my-3 mt-18`}
                                       >
                                          {" "}
                                          {/* Log in */}
                                          {isLoading ? <Spin /> : "Log in"}
                                       </button>
                                    </div>
                                 </form>
                              )}
                           </Formik>
                           <div className="d-flex justify-content-center mt-4">
                              <p
                                 style={{
                                    color: "#555",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    margin: "0",
                                    padding: 0
                                 }}
                              >
                                 Don't have an account yet?{" "}
                                 <Link
                                    to="/auth/registration"
                                    className="ml-2 sign-up-btn"
                                    id="kt_login_signup kt_login_signup_red"
                                    style={{ color: "#b7284d" }}
                                 >
                                    Sign up
                                 </Link>
                              </p>
                           </div>
                        </div>
                        {/* )} */}
                     </>
                  </div>
               </div>
            </div>
            <div className="form-banner"></div>
         </div>
      </>
   );
};

export default Header;
