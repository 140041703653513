import React from "react";
import "./styleUnlocking.scss";
import conferenceImg from "./assets/conference.svg";

const UnlockingInnovation = () => {
  return (
    <div
      className="mainContainer-ASK"
      style={{
        backgroundColor: "#B7284D",
      }}
    >
      <div className="container">
        <div className="mainContainer-wrapper">
            <div className="image-container-ASK">
              <img
                src={conferenceImg}
                alt="People"
                width={"100%"}
                style={{ height: "100%" }}
              />
            </div>
            <div className="text-container-ASK" style={{ gap: "32px" }}>
              <div className="d-flex flex-column" style={{ gap: "32px" }}>
                <h2 style={{ color: "#fff", margin: 0 }}>Quality Principles</h2>
                <span
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    letterSpacing: "0.32px",
                  }}
                >
                  The Kosovo Agency of Statistics is dedicated to providing timely,
                  reliable, and objective statistical data for effective planning and
                  development. The system supports various stakeholders, including
                  government bodies, academic institutions, the international
                  community, businesses, and the general public.
                </span>
                <ul
                  className="d-flex flex-column"
                  style={{ gap: "24px", padding: "0 0 0 25px" }}
                >
                  <li style={{ color: "#fff" }}>
                    <strong>Relevance</strong>: Statistics address user needs,
                    covering fields such as demographics, economy, and environment.
                    Data collection is streamlined to achieve results efficiently.
                  </li>
                  <li style={{ color: "#fff" }}>
                    <strong>Neutrality</strong>: Statistics are developed and shared
                    impartially, treating all users equally.
                  </li>
                  <li style={{ color: "#fff" }}>
                    <strong>Confidentiality</strong>: Data collection, processing, and
                    publication follow rigorous standards to protect user information.
                  </li>
                  <li style={{ color: "#fff" }}>
                    <strong>Professional Independence</strong>: Statistics are created
                    and shared independently, free from external pressures.
                  </li>
                  <li style={{ color: "#fff" }}>
                    <strong>Transparency</strong>: Users are informed about their
                    obligations and data protection measures. Results are accessible
                    to everyone.
                  </li>
                  <li style={{ color: "#fff" }}>
                    <strong>Statistical Confidentiality</strong>: Data is protected
                    and used only for statistical purposes, not for administrative or
                    legal actions.
                  </li>
                  <li style={{ color: "#fff" }}>
                    <strong>Coordination</strong>: The Agency coordinates and
                    harmonizes the national statistical system.
                  </li>
                  <li style={{ color: "#fff" }}>
                    <strong>Data Use</strong>: Individual data is used solely for
                    statistical purposes as per Statistical Law No.04/L-36, and not
                    for administrative or judicial use.
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockingInnovation;
