import React from "react";
import "./styles.scss";
import { Collapse } from "antd";
import PeopleImg from "./assets/people-policies.svg";

const spanStyle = {
  color: "#000",
  fontSize: "16px;",
  letterSpacing: "0.32px",
};

const Policies = () => {
  const items = [
    {
      key: "1",
      label: "Quality",
      children: (
        <span style={spanStyle}>
          Mission of the Statistical Agency is to meet the requirements of users
          with qualitative, reliable and objective statistical data.
        </span>
      ),
    },
    {
      key: "2",
      label: "Research and methodology",
      children: (
        <div className="d-flex flex-column" style={{ gap: "20px" }}>
          <span style={spanStyle}>
            Mission of the Statistical Agency is to meet the requirements of
            users with qualitative, reliable and objective statistical data.
          </span>
          <span style={spanStyle}>
            KAS employs a variety of instruments to collect statistical data,
            including direct interviews, surveys, administrative records, and
            the use of Information and Communication Technology (ICT).
          </span>
          <span style={spanStyle}>
            KAS engages well-trained enumerators to ensure that data is
            collected accurately and professionally.
          </span>

          <span style={spanStyle}>
            KAS carefully applies quality control throughout the entire survey
            process, including data verification and addressing any
            discrepancies or contradictions.
          </span>
        </div>
      ),
    },
    {
      key: "3",
      label: "Dissemination",
      children: (
        <div className="d-flex flex-column" style={{ gap: "20px" }}>
          <span style={spanStyle}>
            Dissemination of statistical data includes the process of
            dissemination and presentation to users of these data in general.
            This process aims to ensure that data is as accessible and
            understandable as possible to users.
          </span>
          <span style={spanStyle}>
            The Kosovo Agency of Statistics usually uses a wide range of
            statistical data Dissemination methods, including :
            <ul style={{ padding: "0 0 0 20px" }}>
              <li>Calendar of Publications</li>
              <li>Publication of Reports and Tables</li>
              <li>Online Data Supply</li>
              <li>Cooperation with the Media</li>
              <li>Comments and Interpretations</li>
              <li>Presentation at Conferences and Seminars</li>
            </ul>
          </span>
          <span style={spanStyle}>
            Every two years, the Dissemination unit conducts a User Satisfaction
            Survey and a KAS staff satisfaction survey.
          </span>

          <span style={spanStyle}>
            KAS publishes Official Statistics in Albanian, Serbian and English
            language.
          </span>
        </div>
      ),
    },
    {
      key: "4",
      label: "Copyright notice and free data reuse",
      children: (
        <div className="d-flex flex-column" style={{ gap: "20px" }}>
          <span style={spanStyle}>
            All published statistics are readily available from KAS’s website
            and the ASKData database will be continuously be updated to contain
            all data.
          </span>
          <span style={spanStyle}>
            The statistics can be freely used and quoted, provided that KAS is
            stated as source. The statistical results must be available to all
            users at the same time.
          </span>
          <span style={spanStyle}>
            The statistics are provided with access to documentation of methods,
            quality and concepts in the form of quality declarations. This will
            cover all statistics from 2017.
          </span>
        </div>
      ),
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };
  return (
    <>
      <div className="d-flex flex-column policiesSection-ASK">
        <div className="container">
          <span className="policies-header">Our policies</span>
          <div className="d-md-flex" style={{ gap: "60px", marginTop: '32px' }}>
            <Collapse
              onChange={onChange}
              items={items}
              className="collapseContainer"
            />
            <div style={{ width: "100%", maxWidth: "100%" }}>
              <img
                src={PeopleImg}
                alt="people"
                style={{ width: "100%", maxWidth: "100%", maxHeight: "500px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policies;
